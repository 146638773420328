import * as React from "react"
import LayoutSystemsIndustrie from "../../components/layout-systems-industrie";

const SystemPage = () => {
  return (
    <LayoutSystemsIndustrie>

    </LayoutSystemsIndustrie>
  )
}

export default SystemPage